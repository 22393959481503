/** Angular */
import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { DMYDatePipe } from "../../layout";
import * as moment from "moment";

@Injectable()
export class TypesUtilsService {
  constructor(private datePipe: DatePipe, private dmyDatePipe: DMYDatePipe) {}

  formatDate(date: any, format: string = "dd-MM-yyyy") {
    const isMomentDateValid = (date): boolean => {
      return moment(date).isValid();
    };

    const isDateValid = (date): boolean => {
      const regex = /^19|20\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]) (?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
      return regex.test(date);
    };

    if (!date && (!isDateValid(date) || !isMomentDateValid(date))) {
      return;
    }
    return this.datePipe.transform(date, format);
  }

  getDateFromString(dateInStr: string = "", returnDateIfNoValue = true): null | string | Date {
    if (dateInStr) {
      return this.dmyDatePipe.transform(dateInStr);
    }

    return returnDateIfNoValue ? new Date() : null;
  }

  static economicAccountDisplayFn(account: any): string {
    return account?.entity_object ? (account.entity_id || "") + " - " + account.entity_object.name : "";
  }
}
